// External imports
import { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

// Local imports
import App from './app'
import initI18n from './lang'
import './styles/main.scss'

// Global initialization
initI18n()

const htmlRoot = document.getElementById('root') as HTMLElement
const reactRoot = ReactDOM.createRoot(htmlRoot)

reactRoot.render(
  process.env.REACT_APP_SCRICT_MODE === 'true' ? (
    <StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </StrictMode>
  ) : (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  )
)
